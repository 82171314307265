import React from "react"

import Layout from "../components/layout/Layout"
import SEO from "../components/Seo"
import { GridContainer, GridItemFullWidth } from "../components/layout/Grid"
import H2Header from "../components/H2Header"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <GridContainer>
      <GridItemFullWidth>
        <H2Header>NOT FOUND</H2Header>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </GridItemFullWidth>
    </GridContainer>
  </Layout>
)

export default NotFoundPage
